import { http } from "../helpers/easyindustriaapi/config";

export default class AtendimentoController {
    constructor() {
        this.currentUser = JSON.parse(localStorage.getItem('user'));
        this.currentEmpresa = JSON.parse(localStorage.getItem('currentEmpresa'));
    }

    getFiles(atendimento) {
        const promisse = http.get(`/atendimento/${atendimento.id}/arquivos/?empresa_id=${this.currentEmpresa.id}`);
        return promisse.then((response) => {
            const items = response.data.data;
            return items || []
        }).catch((error) => {
            console.log(error);
            throw this.handleError(error);
        });
    }

    async uploadFile(file, atendimento) {
        try {
            const formData = new FormData();
            formData.append("file", file);
            const headers = { 'Content-Type': 'multipart/form-data' };
            const response = await http.post(
                `/atendimento/${atendimento.id}/upload/?empresa_id=${this.currentEmpresa.id}`, 
                formData,
                { headers }
            );
            return response.data;
        } catch (error) {
            console.log(error);
            throw this.handleError(error);
        }
    }

    async delete(id) {
        try {
            const response = await http.delete(`/arquivo/${id}?empresa_id=${this.currentEmpresa.id}`);
            return response.data;
        } catch (error) {
            console.log(error);
            throw this.handleError(error);
        }
    }

    downloadFile(file) {
        const promisse = http.get(`/arquivo/${file.id}?empresa_id=${this.currentEmpresa.id}`, file);
        return promisse.then((response) => {
            const item = response.data;
            return item || {}
        }).catch((error) => {
            console.log(error);
            throw this.handleError(error);
        });
    }

    handleError(error) {
        let messageErro = "";
        if (error.response && error.response.data) {
            messageErro = error.response.data;
        }
        switch (error.response ? error.response.status : null) {
            case 406:
                return 'Erro 406: ' + (messageErro.tipo ? messageErro.tiponome : messageErro.tpag);
            case 404:
                return 'Erro 404: endpoint não encontrado ou servidor fora do ar';
            default:
                return error.message;
        }
    }
} 