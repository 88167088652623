<script>
import Swal from "sweetalert2";
import AtendimentoController from "@/controller/AtendimentoController";

export default {
  props: {
    atendimento: { type: Object },
  },
  data() {
    return {
      file: null,
      controller: null,
      fieldsArquivos: [
        {
          label: 'Código',
          key: 'id',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          label: 'Nome',
          key: 'nome',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Ações',
          key: 'actions',
          thClass: 'text-center',
          tdClass: 'text-center',
        }
      ],
      fieldsAgendamentos: [
        {
          label: 'Título',
          key: 'title',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Início',
          key: 'start',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
          formatter: "formatDateTimeBR"
        },
        {
          label: 'Fim',
          key: 'end',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
          formatter: "formatDateTimeBR"
        },
        {
          label: 'Parecer',
          key: 'desc',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
      ]
    }
  },
  created() {
    this.controller = new AtendimentoController();
    this.carregarArquivos();
  },
  methods: {
    async carregarArquivos() {
      try {
        Swal.fire({
          title: 'Aguarde...',
          text: 'Carregando arquivos...',
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });

        const response = await this.controller.getFiles(this.atendimento);
        const $arquivos = response.map(arquivo => ({
          id: arquivo.id,
          nome: arquivo.nome,
          url: arquivo.url
        }));
        this.$set(this.atendimento, 'arquivos', $arquivos);
        
        Swal.close();
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Erro!',
          text: error
        });
      }
    },
    onFileChange() {
      this.file = this.$refs.file.files[0];
    },
    async doUpload() {
      try {
        Swal.fire({
          title: 'Aguarde...',
          text: 'Enviando arquivo...',
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });

        const $arquivo = await this.controller.uploadFile(this.file, this.atendimento);
        console.log($arquivo);
        this.atendimento.arquivos.push({
          id: $arquivo.id,
          nome: $arquivo.nome,
          url: $arquivo.url
        });
        this.file = null;
        this.$refs.file.value = '';
        
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Arquivo enviado com sucesso',
          timer: 1500
        });
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Erro!',
          text: error
        });
      }
    },
    async downloadFile(file) {
      try {
        Swal.fire({
          title: 'Aguarde...',
          text: 'Preparando download...',
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });

        await this.controller.downloadFile(file);
        Swal.close();
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Erro!',
          text: error
        });
      }
    },
    async deleteFile(file) {
      try {
        const result = await Swal.fire({
          title: 'Tem certeza?',
          text: "Esta ação não poderá ser revertida!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim, excluir!',
          cancelButtonText: 'Cancelar'
        });

        if (result.isConfirmed) {
          // Mostra loader durante a exclusão
          Swal.fire({
            title: 'Aguarde...',
            text: 'Excluindo arquivo...',
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });

          // Aguarda a exclusão no servidor
          await this.controller.delete(file.id);
          
          // Remove o arquivo do array
          const index = this.atendimento.arquivos.findIndex(a => a.id === file.id);
          if (index > -1) {
            this.atendimento.arquivos.splice(index, 1);
          }
          
          // Mostra mensagem de sucesso
          Swal.fire({
            icon: 'success',
            title: 'Excluído!',
            text: 'O arquivo foi excluído com sucesso.',
            timer: 1500
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Erro!',
          text: error
        });
      }
    }
  }
}
</script>

<template>
<div class="atendimento">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div role="tablist">
            <!-- Card Paciente -->
            <b-card no-body class="mb-1 shadow-none">
              <b-card-header header-tag="header" role="tab">
                <h6 class="m-0">
                  <a
                    v-b-toggle.accordion-1
                    class="text-dark"
                    href="javascript: void(0);"
                  >Paciente</a>
                </h6>
              </b-card-header>
              <b-collapse id="accordion-1" visible accordion="atendimento-accordion" role="tabpanel">
                    <b-card-body>
                      <div class="row">
                        <div class="col-md-5 col-sm-12 border-detail m-2 p-2">Nome: <strong>{{ atendimento.pacient.nome  }}</strong></div>
                      </div>
                      <div class="row">
                        <div class="col-md-5 col-sm-12 border-detail m-2 p-2">Nome da Mãe: <strong>{{ atendimento.pacient.mae  }}</strong></div>
                        <div class="col-md-6 col-sm-12 border-detail m-2 p-2">Nome do Pai: <strong>{{ atendimento.pacient.pai  }}</strong></div>
                      </div>
                      <div class="row">
                        <div class="col-md-4 col-sm-12 border-detail m-2 p-2">C. P. F.: <strong>{{ atendimento.pacient.cpf  }}</strong></div>
                        <div class="col-md-4 col-sm-12 border-detail m-2 p-2">Contato: <strong>{{ atendimento.pacient.contact }}</strong></div>
                        <div class="col-md-2 col-sm-12 border-detail m-2 p-2">
                          É particular?: <b-badge variant="success" v-if="atendimento.pacient.is_private == 1">Sim</b-badge>
                          <b-badge variant="danger" v-else>Não</b-badge>
                        </div>
                      </div>
                    </b-card-body>
                  </b-collapse>
            </b-card>
            <!-- Card Profissional -->
            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a
                      v-b-toggle.accordion-2
                      class="text-dark"
                      href="javascript: void(0);"
                    >Profissional</a>
                  </h6>
                </b-card-header>
                <b-collapse id="accordion-2" accordion="atendimento-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="row">
                      <div class="col-md-12">
                        Nome: {{ atendimento.professional ? atendimento.professional.name : '' }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        E-mail {{ atendimento.professional ? atendimento.professional.email : '' }}
                      </div>
                      <div class="col-md-4">
                        Contato: {{ atendimento.professional ? atendimento.professional.contact : '' }}
                      </div>
                      <div class="col-md-2">
                        Conselho: {{ atendimento.professional ? atendimento.professional.council : '' }}
                      </div>
                      <div class="col-md-2">
                        Registro: {{ atendimento.professional ? atendimento.professional.council : '' }}
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
            </b-card>
            <!-- Card Tratamento -->
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" role="tab">
                <h6 class="m-0">
                  <a
                    v-b-toggle.accordion-3
                    class="text-dark"
                    href="javascript: void(0);"
                  >Tratamento</a>
                </h6>
              </b-card-header>
              <b-collapse id="accordion-3" accordion="atendimento-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text><p>Tratamento: {{ atendimento.specialty ? atendimento.specialty.name : '' }}</p></b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
            <!-- Card Abordagem -->
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" role="tab">
                <h6 class="m-0">
                  <a
                    v-b-toggle.accordion-4
                    class="text-dark"
                    href="javascript: void(0);"
                  >Abordagem</a>
                </h6>
              </b-card-header>
              <b-collapse id="accordion-4" accordion="atendimento-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>
                    <p>Abordagens: <b-badge data-name="approach" class="field-approach" variant="info">{{ atendimento.approach ? atendimento.approach.name : '' }}</b-badge></p>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
            <!-- Card Agendamentos -->
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" role="tab">
                <h6 class="m-0">
                  <a
                    v-b-toggle.accordion-5
                    class="text-dark"
                    href="javascript: void(0);"
                  >Agendamentos</a>
                </h6>
              </b-card-header>
              <b-collapse id="accordion-5" accordion="atendimento-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>
                    <b-table
                      :fields="fieldsAgendamentos"
                      striped
                      hover
                      :items="atendimento.agendas"
                    >
                      <template v-slot:cell(title)="row">
                        {{ truncate(row.item.title, 50) }}
                      </template>
                    </b-table>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
            <!-- Card Arquivos -->
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" role="tab">
                <h6 class="m-0">
                  <a
                    v-b-toggle.accordion-6
                    class="text-dark"
                    href="javascript: void(0);"
                  >Arquivos</a>
                </h6>
              </b-card-header>
              <b-collapse id="accordion-6" accordion="atendimento-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>
                    <b-table
                      :fields="fieldsArquivos"
                      striped
                      hover
                      :items="atendimento.arquivos"
                    >
                      <template #cell(nome)="row">
                        <a :href="row.item.url" target="_blank">{{row.item.nome}}</a>
                      </template>
                      <template #cell(actions)="row">
                        <b-button variant="danger" @click="deleteFile(row.item)">Excluir</b-button>
                      </template>
                    </b-table>
                    <div class="row">
                      <div class="col-md-6 pt-3 mt-3">
                        <div class="custom-file">
                          <form @submit.prevent="doUpload">
                            <input id="customFile" @change="onFileChange" type="file" class="custom-file-input" ref="file"/>
                            <label class="custom-file-label" for="customFile">{{ (this.file) ? this.file.name : 'Selecionar arquivo' }}</label>
                            <button type="submit" :disabled="!file" class="btn btn-primary">Enviar Arquivo</button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p>Criado: <b-badge data-name="created_at" class="field-created_at" variant="secondary">{{ formatDateTimeBR(atendimento.created_at) }}</b-badge></p>
  <p>Última alteração: <b-badge data-name="updated_at" class="field-updated_at" variant="secondary">{{ formatDateTimeBR(atendimento.updated_at) }}</b-badge></p>
</div>
</template>

<style scoped>
  .border-detail {
    border-right: 1px solid #D5D6D7 !important;
  }
</style>